@import 'components/global.scss';

.app {
  width: 100%;
  min-height: 100%;
  background-color: $c-bg;
  height: 100vh;
  position: relative;
  overflow-y: auto;

  @media (max-width: $mobile) {
    height: 100dvh;
  }
  & > :last-child {
    padding: 0 $scale2 $scale2;
    @media (max-width: $max-width) {
      padding: 0 $scale1 $scale1;
    }
  }
}

.app-bg-gray {
  background-color: $c-gray;
}

.app-bg-orange {
  background-color: $c-orange;
}

.app-bg-blue {
  background-color: $c-bg-blue;
}
